<template>
	<ValidationObserver>
		<form @submit.prevent="saveData">
			<b-card title="Update Inspiration">
				<!-- <div class="form-group form-row">
					<label for="title" class="col-4 col-form-label">Title:</label>
					<div class="col">
						<validation-provider
							name="title"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								id="title"
								type="text" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								class="form-control" 
								placeholder="Title.."
								v-model="formPayload.description"
							>
							<small
								v-for="(validation, index) in validations.name"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div> -->
				<!-- <div class="form-group form-row" v-if="formPayload.content_type != 'text_only'">
					<label for="category" class="col-4 col-form-label">Category:</label>
					<div class="col">
						<v-select
							id="category"
							v-model="formPayload.category"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="content_category_name"
							placeholder="Pilih Category"
							:options="category"
							@change="inputSubCategory($event.content_category_slug)" 
						/>
						<small
							v-for="(validation, index) in validations.category"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</div>
				</div>
				<div class="form-group form-row" v-if="formPayload.content_type != 'text_only'">
					<label for="subCategory" class="col-4 col-form-label">Sub Category:</label>
					<div class="col">
						<v-select
							id="subCategory"
							v-model="formPayload.sub_category"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							label="content_subcategory_name"
							placeholder="Pilih Sub Category"
							:options="subCategory"
						/>
						<small
							v-for="(validation, index) in validations.sub_category"
							:key="`errorName${index}`"
							class="text-danger"
						>{{ validation }}</small>
					</div>
				</div> -->
				<!-- <div class="form-group form-row">
					<label for="thumbnail" class="col-4 col-form-label">Thumbnail:</label>
					<div class="col">
						<b-form-file
							id="thumbnail"
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							@change="changePhoto($event)"
						/>
						<div
							v-if="formPayload.thumbnail"
							class="mt-1 relative"
						>
							<img
								:src="thumbnail_url"
								height="200" width="200"
							>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle"
								@click="cancelPhoto()"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div> -->
				<div class="form-group form-row">
					<label class="col-4 col-form-label">Description:</label>
					<div class="col">
						<vue-editor v-model="formPayload.article" :editor-toolbar="customToolbar" />
					</div>
				</div>
				<!-- <div class="form-group form-row">
					<label for="thumbnail" class="col-4 col-form-label">Thumbnail:</label>
					<div class="col">
						<b-form-file
							id="thumbnail"
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							@change="changePhoto($event)"
						/>
						<div
							v-if="formPayload.thumbnail"
							class="mt-1 relative"
						>
							<img
								:src="thumbnail_url"
								height="200" width="200"
							>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle"
								@click="cancelPhoto()"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div> -->
				<div class="form-group form-row">
					<label class="col-4 col-form-label" for="files">Files:</label>
					<div class="col">
						<b-form-file id="files" placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..." @change="onFileSelected" />
						<div class="mt-2 w-50 position-relative" v-for="(files, indexFiles) in fileUrl" :key="indexFiles">
							<img :src="files.content" class="w-100" alt="" v-if="files.content_title == 'text_only'">
							<video class="w-100" controls v-else>
								<source :src="files.content" type="video/mp4" />
								Your browser does not support the video tag.
							</video>
							<b-button variant="gradient-danger" style="position: absolute; top: -10px;"
								class="btn-icon rounded-circle" @click="deleteFile(indexFiles)">
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div>
				<!-- <div class="form-group form-row" v-if="formPayload.content_type != 'article'">
					<label class="col-4 col-form-label" for="files">Files:</label>
					<div class="col">
						<b-form-file
							id="files"
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							@change="onFileSelected"
						/>
						<div class="mt-2 w-50 position-relative" v-for="(files, indexFiles) in filesUrl" :key="indexFiles">
							<video class="w-100" controls>
								<source :src="files.content" type="video/mp4" />
								Your browser does not support the video tag.
							</video>
							<img :src="files.content" class="w-100" alt="">
							<b-button
								variant="gradient-danger"
								style="position: absolute; top: -10px;"
								class="btn-icon rounded-circle"
								@click="deleteFile(indexFiles)"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div> -->
			</b-card>
			<b-col cols="12" class="p-0 mt-2">
				<button variant="primary" class="btn waves-effect waves-float waves-light btn-primary">
					Save
				</button>
				&nbsp;
				&nbsp;
				<a @click="handleBackButton()" variant="secondary"
					class="btn waves-effect waves-float waves-light btn-secondary">
					Cancel
				</a>
			</b-col>
		</form>
	</ValidationObserver>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BFormFile,
	BButton,
	BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { VueEditor } from "vue2-editor";
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormFile,
		VueEditor,
		vSelect,
		flatPickr,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			required,
			isLoading: false,
			formPayload: {
				article: '',
				description: '',
				thumbnail: '',
				category: '',
				sub_category: '',
				files: [],
			},
			fileUrl: [],
			thumbnail_url: '',
			filesUrl: [],
			customToolbar: [
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				["bold", "italic"],
				["image", "video"],
				["link"],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
			],
			snowOption: {
				theme: 'snow',
			},
			category: [],
			subCategory: [],
			validations: ''
		}
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},

	created() {
		this.loadData()
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		inputSubCategory(slug) {
			this.$http.get(`/admin/sub-category-contents?content_category_slug=${slug}`)
				.then(response => {
					this.subCategory = response.data.data
				})
		},
		categoryName() {
			this.$http.get('/admin/category-contents')
				.then(response => {
					this.category = response.data.data
					console.log('====================================');
					console.log(this.category);
					console.log('====================================');
				})
		},
		async subCategoryName() {
			await this.$http.get('/admin/sub-category-contents')
				.then(response => {
					this.subCategory = response.data.data
					console.log(this.subCategory);
				})
		},
		cancelPhoto() {
			this.formPayload.thumbnail = null
		},
		changePhoto(event) {
			this.formPayload.thumbnail = event.target.files[0]
			this.showPhoto(event.target.files[0])
		},
		showPhoto(file) {
			const reader = new FileReader()
			reader.onload = e => {
				this.thumbnail_url = e.target.result
			}
			reader.readAsDataURL(file)
		},
		deleteFile(index) {
			this.formPayload.files.splice(index, 1);
			this.filesUrl.splice(index, 1);
		},
		onFileSelected(event) {
			// this.formPayload.files = [];
			this.formPayload.files.push(event.target.files[0]);
			this.showfile(this.formPayload.files);
		},
		showfile(file) {
			for (let index = 0; index < file.length; index++) {
				const element = file[index];
				const reader = new FileReader();
				reader.onload = (e) => {
					this.fileUrl.push({
						file: e.target.result,
						type: element.type,
					});
				};
				reader.readAsDataURL(element);
			}
		},
		loadData() {
			const params = this.$route.params.slug
			this.$http.get('/admin/inspirations/' + params)
				.then(response => {
					console.log('====================================');
					console.log(response.data.data);
					console.log('====================================');
					this.formPayload = response.data.data
					this.thumbnail_url = this.formPayload.thumbnail
					this.fileUrl = this.formPayload.files
				}).then(() => {
					this.categoryName();
					this.subCategoryName();
				}).catch(error => {
				})
		},

		preparePayload() {
			const form = new FormData()
			if (this.formPayload.category !== null && this.formPayload.subCategory !== null) {
				this.formPayload.id_category_content = this.formPayload.category ? this.formPayload.category.id : ''
				this.formPayload.id_subcategory_content = this.formPayload.sub_category ? this.formPayload.sub_category.id : ''
			}
			for (const key in this.formPayload) {
				if (this.formPayload.hasOwnProperty(key)) {
					if (key != 'thumbnail' && key != 'files' && key != 'sub_category' && key != 'category') {
						form.append(key, this.formPayload[key])
					} else {
						if (typeof this.formPayload.thumbnail != 'string' && this.formPayload.thumbnail != null) {
							form.append('thumbnail', this.formPayload['thumbnail'])
						}
					}
				}
			}
			if (this.formPayload.files) {
				for (let index = 0; index < this.formPayload.files.length; index++) {
					const element = this.formPayload.files[index];
					if (typeof element.content != 'string') {
						form.append('files[]', element)
					}
				}
			}
			return form;
		},

		saveData() {
			const slugs = this.$route.params.slug
			var form = this.preparePayload();
			this.isLoading = true
			form.append('_method', 'PATCH');
			this.$http.post(`/admin/inspirations/${slugs}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
				.then(response => {
					successNotification(this, 'Success', 'Inspiration Updated!')
					this.$router.push({ name: 'inspiration' })
					this.isLoading = false
				})
				.catch(error => {
					if (error.response.data.meta.messages.length > 0) {
						errorNotification(this, 'Oops!', error.response.data.meta.messages)
					}
					if (error.response.data.meta.validations) {
						this.validations = error.response.data.meta.validations
						errorNotification(this, 'Nama', this.validations.name.join(' '))
					}
					this.isLoading = false
				})
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>